.header-color {
  background: #c21500;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ffc500, #c21500);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ffc500, #c21500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid{
  background: #c21500;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffc500, #c21500);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffc500, #c21500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.layout{
  height: 100%;
}
.avatar-image{
  height: 250px;
  padding-top: 5em;
}

.banner-text{
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1{
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr{
  border-top:  5px dotted white;
  width: 50%;
  margin: auto;

}

.banner-text p {
   color:  white ;
   font-size: 20px;
   padding : .5em;
}

.social-links{
  display: flex;
  justify-content: space-between;

  width: 50%;
  margin: auto;
}

.social-links i{
   color:  white;
   font-size: 5em;
   padding: 3px;
}

.projects-grid{
  display: flex;
}

.projects-grid li{
   list-style-type: square;
   color: gray;
  
   
}


/* Contact Page styles */

.contact-body {
   margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 2em;

}

.contact-grid{
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
 
  margin-bottom:20px ; 
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family:  'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr{
border-top:5px dotted black;
width: 50%;
margin: auto;
}

.contact-list i {
  font-size: 66px; 
  padding-right: 5rem;
}

/* Resume Page CSS*/

.resume-right-col{
  background: #27221f;
  color: white;
  padding: 2em;
}

.about-grid {
  
    text-align: center;
    justify-content: center;
    width: 80%;
    height: 550px;
    background: grey;
    color: black;
   
    margin-bottom:20px ; 

}

.about-intro{
  text-align: center;
  justify-content: left;
  width: 80%;
  height: 250px;
  background-color: white;
  color: black;
  font-family: 'Anton', sans-serif;

}

.about-intro p {
  font: italic;
  font-family:  'Oxygen', sans-serif;
}


.carousels.tools{
  justify-content: left;
  width: 80%;
  height: 250px;
  background-color: grey;
  color: black;
  font-family: 'Anton', sans-serif;
}